.container {
  display: flex;
  position: relative;
  flex-direction: column;
}
.containerSm {
  display: flex;
  position: static;
  flex-direction: column;
}
.containerSm .detailsWrapper {
  left: 0;
  right: 0;
  width: 100%;
}
.containerSm .details {
  border-radius: 0;
}
.details {
  overflow: hidden;
  border-radius: 4px;
}
.detailsWrapper {
  top: 56px;
  width: 257px;
  right: 0;
  z-index: 10;
  position: absolute;
}
.detailsWrapper:before {
  top: -6px;
  right: 13px;
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 6px solid #fff;
}