.groupContainer {
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.header {
  color: #212121;
  padding: 16px;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 500;
}
.counters {
  display: flex;
  background: #f3f3f3;
  flex-direction: column;
}
.counterWrapper {
  padding: 8px 16px;
  box-sizing: border-box;
}
.footer {
  color: #878787;
  padding: 16px;
  font-size: 14px;
  box-sizing: border-box;
}