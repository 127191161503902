.container {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 19px 16px;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
}
.container:hover {
  background: #3f4446;
}
.text {
  color: #fff;
  font-size: 12px;
  margin-left: 6px;
  font-weight: 300;
  letter-spacing: 0.8px;
}