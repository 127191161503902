.container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  position: fixed;
  background: rgba(0,0,0,0.5);
  align-items: center;
  justify-content: center;
}
.wrapper {
  width: 880px;
  height: auto;
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  box-shadow: 0 15px 25px 0 rgba(0,0,0,0.21), 0 19px 38px 0 rgba(0,0,0,0.3);
  max-height: 80%;
}
.mdWrapper {
  width: 95%;
  height: auto;
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  max-width: 720px;
  box-shadow: 0 15px 25px 0 rgba(0,0,0,0.21), 0 19px 38px 0 rgba(0,0,0,0.3);
  max-height: 80%;
}
.smWrapper {
  width: 95%;
  height: auto;
  display: flex;
  position: relative;
  flex-flow: column nowrap;
  max-width: 280px;
  box-shadow: 0 15px 25px 0 rgba(0,0,0,0.21), 0 19px 38px 0 rgba(0,0,0,0.3);
  max-height: 80%;
}
.childrenWrapper {
  height: auto;
  overflow: auto;
  background: #fff;
  border-radius: 4px;
}
.closeBtn {
  top: -19.2px;
  right: -19.2px;
  color: white;
  width: 48px;
  cursor: pointer;
  height: 48px;
  display: flex;
  z-index: 11;
  position: absolute;
  font-size: 40px;
  background: #ff6d00;
  box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
  font-weight: bold;
  line-height: 1;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}
.closeBtn:active {
  box-shadow: none;
}
.closeBtn:after {
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M16 34l9-9 9-9m-18 0l9 9 9 9'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}