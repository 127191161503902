.container {
  width: 100%;
  display: flex;
  outline: none;
  flex-direction: column;
}
.inputWrapper {
  width: 100%;
  color: #444;
  height: 56px;
  padding: 12px 16px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: #fff;
}
.invalidInputWrapper {
  color: #d50000;
  border-width: 2px;
  border-style: solid;
  border-color: #d50000;
}
.suggestionsContainer {
  display: flex;
  outline: none;
  padding: 8px 0;
  overflow: auto;
  max-height: 264px;
  flex-direction: column;
  background-color: #fff;
}
.item {
  color: #878787;
  cursor: pointer;
  padding: 12px 24px;
  font-size: 16px;
  line-height: 20px;
}
.item b {
  color: #212121;
  font-weight: 500;
}
.item span:before {
  content: ", ";
}
.itemHovered {
  background: #eff9da;
}