.pickerWrapper {
  width: 100%;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  background-color: #fff;
}
.inputDate span {
  color: #878787;
}
.unavailableDate {
  color: #ccc;
  cursor: initial;
}
.hoveredDate {
  color: #fff;
  border-radius: 4px;
}
.selectedStartDate {
  color: #fff;
  background: #93d309;
  border-radius: 4px;
}
.selectedEndDate {
  color: #fff;
  background: #1e88e5;
  border-radius: 4px;
}
.rangedDate {
  background: #f3f3f3;
}