.wrapper {
  color: white;
  background-color: #4c5356;
}
.wrapper a {
  color: white;
  text-decoration: none;
}
.wrapper p {
  margin-top: 0;
  margin-bottom: 10px;
}
.wrapper h4 {
  margin: 18px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.wrapperSm {
  color: white;
  background-color: #4c5356;
}
.wrapperSm a {
  color: white;
  text-decoration: none;
}
.wrapperSm p {
  margin-top: 0;
  margin-bottom: 10px;
}
.wrapperSm h4 {
  margin: 18px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.container {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 1188px;
  box-sizing: border-box;
}
.containerSm {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 1188px;
  box-sizing: border-box;
  text-align: center;
}
.containerSm .tgBtnWrapper {
  display: flex;
  justify-content: center;
}
.containerSm .payments {
  display: flex;
  justify-content: center;
}
.containerMd {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 768px;
  box-sizing: border-box;
}
.rowSm > div+div {
  margin-top: 48px;
}
.row {
  display: flex;
  justify-content: space-between;
}
.col ul {
  padding: 0;
  list-style: none;
}
.colMid {
  padding-top: 18px;
}
.tgBtnWrapper {
  padding-bottom: 0;
}
.vkBtnWrapper {
  padding-bottom: 6px;
}
.colSocial {
  order: -1;
}
.colSocial ul {
  padding: 0;
  list-style: none;
}
.nav li {
  margin-bottom: 8px;
}
.nav li a {
  color: rgba(255,255,255,.8);
}
.nav li a:hover {
  color: white;
}
.phone {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 1px;
}
.email {
  font-size: 16px;
  margin-top: 0;
  font-weight: 500;
  line-height: 1;
}
.social > li {
  display: inline-block;
  margin-right: 16px;
}
.copyright {
  opacity: 0.8;
  font-size: 14px;
}
.payments {
  gap: 8px;
  display: flex;
}
.payments > img {
  width: 40px;
}
.link {
  color: #ffffff!important;
  cursor: pointer;
}