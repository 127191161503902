.opened span:nth-child(1) {
  top: 0;
  left: 0;
  transform: rotate(45deg);
}
.opened span:nth-child(2) {
  width: 0%;
  opacity: 0;
}
.opened span:nth-child(3) {
  top: 13px;
  left: 0;
  transform: rotate(-45deg);
}
.wrapper {
  width: 18px;
  height: 12px;
  cursor: pointer;
  position: relative;
}
.wrapper span {
  left: 0;
  width: 100%;
  height: 2px;
  display: block;
  opacity: 1;
  position: absolute;
  transform: rotate(0deg);
  background: #ffffff;
  transition: 250ms ease-in-out;
  will-change: transform;
  transform-origin: left center;
}
.normal span:nth-child(1) {
  top: 0;
}
.normal span:nth-child(2) {
  top: 5px;
}
.normal span:nth-child(3) {
  top: 10px;
}