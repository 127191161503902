.container {
  outline: none;
  position: relative;
}
.activePopover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}
.popover {
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9999;
  position: absolute;
  transform-origin: top;
}
.popoverOverlay {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  position: fixed;
  transform-origin: top;
}
.popoverEnter {
  opacity: 0.001;
  transform: scaleY(0.001);
  transition: cubic-bezier(0.44, 0.9, 0.5, 0.97) 350ms;
}
.popoverEnterActive {
  opacity: 0.98;
  transform: scaleY(1);
}
.popoverExit {
  opacity: 0.98;
  transform: scaleY(1);
}
.popoverExitActive {
  opacity: 0.001;
  transform: scaleY(0.001);
  transition: cubic-bezier(0.44, 0.9, 0.5, 0.97) 200ms;
}