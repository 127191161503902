.content {
  width: 100%;
  display: flex;
  align-items: center;
}
.chev {
  color: white;
  height: 32px;
  position: relative;
  box-sizing: border-box;
  padding-right: 16px;
}
.chev img {
  transform: rotate(180deg);
}