.container {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 17px 16px;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
}
.container:hover {
  background: #3f4446;
}
.container img {
  width: 18px;
  height: 18px;
}
.containerSm {
  width: 100%;
  cursor: pointer;
  display: flex;
  padding: 6px 8px 6px 4px;
  box-sizing: border-box;
  align-items: center;
  text-decoration: none;
}
.containerSm:hover {
  background: #3f4446;
}
.containerSm img {
  width: 18px;
  height: 18px;
}
.containerSm .text {
  font-size: 12px;
  letter-spacing: 0.429px;
}
.text {
  color: #FFF;
  font-size: 14px;
  font-family: Helvetica Neue;
  font-weight: 500;
  line-height: 12px;
  padding-left: 5px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}