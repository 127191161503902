.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
.containerMd {
  cursor: pointer;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}
.containerSm {
  cursor: pointer;
  display: flex;
  min-height: initial;
  flex-direction: column;
  justify-content: space-between;
}
.content {
  display: flex;
  flex-grow: 1;
  flex-basis: auto;
  flex-shrink: 0;
}
.content > div {
  width: 100%;
}
.headerWrapper {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  position: sticky;
  background: rgb(95, 104, 109);
}
.routeInfoWrapper {
  color: white;
  display: flex;
  min-height: 72px;
  background: #4c5356;
  justify-content: center;
}
.routeInfoWrapper > div {
  padding: 0 24px 10px 24px;
  max-width: 1140px;
  text-align: left;
}
.searchBarWrapper {
  padding-bottom: 16px;
  background-color: rgba(33,33,33,.3);
}
.searchBarWrapper > div {
  margin: 0 auto;
  position: relative;
  max-width: 1140px;
  box-shadow: 0 4px 20px 0 rgba(0,0,0,.3);
  border-radius: 4px;
}