.container {
  display: flex;
  background: #727C81;
  justify-content: center;
}
.containerMd {
  display: flex;
  background: #727C81;
  justify-content: center;
}
.containerMd .btnsWrapper {
  padding: 0 24px;
}
.containerSm {
  display: flex;
  background: #727C81;
  overflow-x: scroll;
  justify-content: center;
}
.containerSm .btnsWrapper {
  padding: 0 16px;
}
.wrapper {
  width: 100%;
  display: flex;
  max-width: 1140px;
}
.btnsWrapper {
  width: 100%;
  display: flex;
  box-sizing: border-box;
}
.btnsWrapper > button:first-child {
  padding-left: 0;
}
.item {
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 16px 10px;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  background-color: transparent;
}
.item:disabled {
  color: #b9bec0;
  cursor: not-allowed;
}
.activeItem {
  color: #fff;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 16px 10px;
  font-size: 14px;
  white-space: nowrap;
  align-items: center;
  background-color: #5e686d;
}
.activeItem:disabled {
  color: #b9bec0;
  cursor: not-allowed;
}
.searchItem {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.searchItem:before {
  width: 16px;
  height: 16px;
  content: "";
  margin-right: 10px;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.5 2.5C3.958.96 5.712.5 8 .5c4.418 0 7.5 3.082 7.5 7.5s-3.082 7.5-7.5 7.5c-2.39 0-4.034-.34-5.5-2' stroke='%23fff'/%3E%3Cpath d='M.646 7.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 8l2.829-2.828a.5.5 0 1 0-.708-.708L.646 7.646zM10 7.5H1v1h9v-1z' fill='%23fff'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
}