.counterContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.counter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
}
.value {
  color: #212121;
  padding: 0 15px;
  display: flex;
  font-size: 20px;
  box-sizing: border-box;
  align-items: center;
}