.content {
  display: flex;
  align-items: center;
}
.leftChev {
  color: white;
  height: 32px;
  position: relative;
  box-sizing: border-box;
  padding-right: 16px;
}
.leftChev:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  border-width: 1px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background-color: transparent;
}
.leftChev:active:before {
  border-color: white;
  background-color: rgba(255,255,255,0.2) !important;
}
.leftChev:hover:before {
  border-color: white;
  background-color: rgba(255,255,255,0.05);
}