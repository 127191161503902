.container {
  width: 100%;
  height: 100%;
  position: relative;
}
.barWrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  transition: all 400ms;
}
.bar {
  height: 5px;
  background: #ff9233;
  transition: all 200ms ease;
}