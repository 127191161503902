.container {
  width: 100%;
}
.backBtn {
  color: #fff;
  padding: 12px 0;
  display: flex;
  font-size: 14px;
  justify-content: center;
  background-color: #5f686d;
}