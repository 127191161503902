.header {
  color: #212121;
  padding: 16px;
  font-size: 16px;
  box-sizing: border-box;
  font-weight: 500;
}
.counterTitle {
  color: #212121;
  display: flex;
  font-size: 14px;
  flex-direction: column;
}
.caption {
  color: #878787;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 400;
}
.footer {
  color: #878787;
  padding: 16px;
  font-size: 14px;
  box-sizing: border-box;
}
.popover {
  width: 100%;
  min-width: 280px;
  background: #fff;
}
.field {
  color: #878787;
  width: 100%;
  cursor: pointer;
  height: 56px;
  padding: 12px 16px;
  display: flex;
  font-size: 16px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}
.field img {
  transition: transform 300ms;
}
.activeField img {
  transform: rotate(-180deg);
}
.headerPopover {
  color: #fff;
  height: 65px;
  padding: 15px 20px;
  display: flex;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
  background: #5F686D;
  align-items: center;
  justify-content: space-between;
}
.headerPopover img {
  width: 12px;
  cursor: pointer;
}
.footerPopover {
  width: 100%;
  font-size: 16px;
  line-height: 1;
  border-radius: 0;
}