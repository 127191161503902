.container {
  display: flex;
  background: #f3f3f3;
  box-sizing: border-box;
  padding-top: 16px;
  flex-direction: column;
}
.logout {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logoutText {
  color: #878787;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.logoutText:hover {
  background: inherit;
}
.menuItem {
  color: #878787;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.menuItem:hover {
  background: #e4e4e4;
}
.email {
  color: #212121;
  padding: 0 16px;
  font-size: 20px;
  box-sizing: border-box;
  font-weight: 500;
  letter-spacing: 1px;
}
.list {
  display: flex;
  padding: 29px 0 42px 0;
  box-sizing: border-box;
  flex-direction: column;
}
.list > span {
  padding: 6px 16px;
  box-sizing: border-box;
}
.footer {
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  border-top: 2px solid #c3c3c3;
}