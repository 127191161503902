.count {
  box-sizing: border-box;
  padding-left: 8px;
}
.tabItem {
  color: #ffffff;
  margin: 0 -20px;
  padding: 10px 20px;
  display: flex;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
  align-items: center;
  font-weight: 500;
  flex-direction: row;
  text-transform: uppercase;
  justify-content: space-between;
}
.tabItemActive {
  background-color: rgba(33, 33, 33, 0.3);
}
.tabItemActive:before {
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  content: "";
  position: absolute;
  background-color: #ff6d00;
}
.ticketsItem {
  display: flex;
}
.ticketsItem .count {
  color: #42A5F5;
}
.ticketsItem circle {
  stroke: #42A5F5;
}
.hotelsItem {
  display: flex;
}
.hotelsItem .count {
  color: #93D309;
}
.hotelsItem circle {
  stroke: #93D309;
}
.cartItem {
  display: flex;
}
.cartItem > img {
  display: block;
  margin-right: 8px;
}
.spinner {
  width: 16px;
  height: 16px;
  margin-left: 8px;
}