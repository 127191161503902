.primaryOrange {
  color: white;
  padding: 24px;
  font-size: 20px;
  box-sizing: border-box;
  text-align: center;
  font-weight: 500;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background-color: #ff6d00;
}
.primaryOrange:hover {
  background-color: #f38938;
}
.primaryOrange:active {
  background-color: #e86636;
}