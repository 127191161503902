.container {
  width: 100%;
  color: #444;
  outline: none;
  position: relative;
  box-sizing: border-box;
  background: white;
  user-select: none;
}
.wrapper {
  width: 100%;
  display: flex;
  padding: 16px 12px;
  flex-flow: row nowrap;
  box-sizing: border-box;
  justify-content: center;
}
.months {
  display: flex;
}
.month {
  width: 100%;
  margin: 0 12px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
}
.navBar {
  left: 0;
  right: 0;
  position: absolute;
}
.navButton {
  top: 0;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.navButtonPrev {
  top: 0;
  left: 20px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4gICAgPHBhdGggZmlsbD0iI0MzQzNDMyIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTkuNzMgMTEuNTRhLjg4Ny44ODcgMCAwIDAgMC0xLjI3NS45MzUuOTM1IDAgMCAwLTEuMzA1IDBsLTUuMTU0IDUuMDk3YS44ODkuODg5IDAgMCAwIDAgMS4yNzdsNS4xNTQgNS4wOTdhLjkzNy45MzcgMCAwIDAgMS4zMDQgMCAuODg3Ljg4NyAwIDAgMCAwLTEuMjc2TDE1LjUwMiAxNmw0LjIyNy00LjQ1OHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center;
}
.navButtonNext {
  top: 0;
  width: 32px;
  right: 20px;
  height: 32px;
  cursor: pointer;
  position: absolute;
  background-size: contain;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj4gICAgPHBhdGggZmlsbD0iI0MzQzNDMyIgZmlsbC1ydWxlPSJub256ZXJvIiBkPSJNMTIuMjcgMTEuNTRhLjg4Ny44ODcgMCAwIDEgMC0xLjI3NS45MzUuOTM1IDAgMCAxIDEuMzA1IDBsNS4xNTQgNS4wOTdjLjM2MS4zNTIuMzYxLjkyMiAwIDEuMjc3bC01LjE1NCA1LjA5N2EuOTM3LjkzNyAwIDAgMS0xLjMwNCAwIC44ODcuODg3IDAgMCAxIDAtMS4yNzZMMTYuNDk4IDE2bC00LjIyNy00LjQ1OHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center;
}
.caption {
  width: 100%;
  display: flex;
  align-items: center;
  line-height: 32px;
  justify-content: center;
}
.caption b {
  font-weight: 500;
  text-transform: capitalize;
}
.weekdays {
  width: 100%;
  color: #878787;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}
.weekdays abbr[title] {
  text-decoration: none;
}
.weekdays :nth-child(6), .weekdays :nth-child(7) {
  color: #d00000;
}
.weekdaysRow {
  width: 100%;
  display: flex;
  flex-grow: 1;
}
.weekday {
  color: #878787;
  padding: 15px 5px 5px 5px;
  min-width: 0;
  font-size: 14px;
  flex-grow: 1;
  box-sizing: border-box;
  text-align: center;
  flex-basis: 0;
  font-weight: 500;
  flex-shrink: 0;
  letter-spacing: 0.5px;
}
.body {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.week {
  width: 100%;
  display: flex;
}
.day {
  cursor: pointer;
  padding: 8px;
  outline: none;
  min-width: 0;
  font-size: 16px;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  box-sizing: border-box;
  line-height: 16px;
  flex-shrink: 0;
  vertical-align: middle;
}
.disabled {
  cursor: initial;
}
.outside {
  cursor: initial;
  background-color: initial !important;
}
.selected {
  cursor: initial;
}
.today {
  cursor: initial;
}