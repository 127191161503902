.wrapper {
  width: 100%;
  height: auto;
  z-index: 9999;
  display: block;
  position: fixed;
  background-color: #3e3e3e;
}
.list {
  margin: 0;
  padding: 20px;
  list-style: none;
}
.item {
  color: #ffffff;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
}
.item:hover {
  opacity: 0.5;
}
.item a {
  color: #ffffff;
  text-transform: uppercase;
  text-decoration: none;
}
.divider {
  border-bottom: 1px solid #878787;
}