.default {
  cursor: pointer;
  border: none;
  padding: 0;
  appearance: none;
  line-height: 1;
  background-color: transparent;
}
.default:disabled {
  cursor: not-allowed;
  pointer-events: none;
}