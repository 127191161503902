.container {
  color: #FFF;
  display: flex;
  background: #5f686d;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 150px;
}
.overlayed {
  height: 100%;
  z-index: 9;
  position: absolute;
}
.spinner {
  width: 60px;
  margin-top: 60px;
}
.content {
  font-size: 20px;
  text-align: center;
  font-weight: 300;
}