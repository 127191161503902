.container {
  cursor: pointer;
  display: flex;
  padding: 10px 9px;
  box-sizing: border-box;
  align-items: center;
  padding-top: 11px;
  text-decoration: none;
}
.container:hover {
  background: #3f4446;
}
.loginText {
  color: white;
  font-size: 14px;
  box-sizing: border-box;
  font-weight: 500;
  padding-left: 4px;
  text-transform: uppercase;
  letter-spacing: 1px;
}