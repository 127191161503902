.container {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 300;
}
.containerSm {
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  font-size: 18px;
  font-weight: 300;
}
.containerSm .secondaryText {
  display: flex;
  flex-direction: column;
}
.containerSm .secondary {
  align-items: flex-end;
}
.containerSm .consist {
  padding-left: 0;
}
.route {
  color: #FFF;
  padding-right: 4px;
  letter-spacing: 0.5px;
}
.secondary {
  color: #b7babb;
  display: flex;
  align-items: center;
}
.dates {
  text-transform: uppercase;
}
.consist {
  padding-left: 4px;
}
.editBtn {
  margin-left: 8px;
}
.editBtn img {
  display: block;
}