.wrapper {
  color: white;
  background-color: rgba(33,33,33,.5);
}
.wrapper a {
  color: rgba(255,255,255,.8);
  text-decoration: none;
}
.wrapper h4 {
  font-size: 14px;
  margin-top: 0;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.wrapper a:hover {
  color: white;
}
.wrapperSm {
  color: white;
  background-color: rgba(33,33,33,.3);
}
.wrapperSm a {
  color: rgba(255,255,255,.8);
  text-decoration: none;
}
.wrapperSm h4 {
  font-size: 14px;
  margin-top: 0;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.wrapperSm a:hover {
  color: white;
}
.container {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 1188px;
  box-sizing: border-box;
}
.containerSm {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 1188px;
  box-sizing: border-box;
}
.containerMd {
  margin: 0 auto;
  padding: 48px 24px;
  max-width: 768px;
  box-sizing: border-box;
}
.ways {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}
.ways > li {
  flex: 1 0 16.666666666666668%;
  margin-top: 5px;
}
.ways > li > a {
  opacity: 0.8;
  font-size: 14px;
}
.waysMd {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}
.waysMd > li {
  flex: 1 0 20%;
  margin-top: 5px;
}
.waysMd > li > a {
  opacity: 0.8;
  font-size: 14px;
}
.waysSm {
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
}
.waysSm > li {
  flex: 1 0 50%;
  margin-top: 5px;
}
.waysSm > li > a {
  opacity: 0.8;
  font-size: 14px;
}
.waysSm > li:nth-child(8n) {
  margin-bottom: 10px;
}