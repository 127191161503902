.container {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.container input, .container span {
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.container > label {
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  justify-content: center;
}
.focusedContainer {
  width: 100%;
  position: relative;
  background-color: transparent;
}
.focusedContainer input, .focusedContainer span {
  transition: all 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.focusedContainer > label {
  display: flex;
  align-items: stretch;
  flex-direction: column-reverse;
  justify-content: center;
}
.focusedContainer .placeholder {
  cursor: pointer;
  transform: translate(0, 0) scale(1);
  margin-bottom: 2px;
}
.input {
  color: inherit;
  border: none;
  padding: 0;
  font-size: inherit;
}
.input:focus {
  outline: 0;
}
.input:focus + span {
  cursor: pointer;
  transform: translate(0, 0) scale(1);
  margin-bottom: 2px;
}
.placeholder {
  cursor: text;
  overflow: hidden;
  max-width: 66.66%;
  transform: translate(0, 1.25em) scale(1.5);
  font-size: .65em;
  white-space: nowrap;
  margin-bottom: 0;
  text-overflow: ellipsis;
  transform-origin: left bottom;
}