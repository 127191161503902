.overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 10px;
  z-index: 999999;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,.8);
}
.close {
  width: 100%;
  height: 100%;
  position: absolute;
}
.popup {
  width: 100%;
  padding: 20px;
  position: relative;
  max-width: 400px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
  border-width: 1px;
  border-style: solid;
  border-color: #aaa;
  background-color: #fff;
}
.header {
  color: #666;
  margin-top: 0;
  font-family: "Trebuchet MS", Tahoma, Arial, sans-serif;
  margin-bottom: 20px;
}
.content {
  overflow: auto;
  max-height: 80vh;
}
.content > p {
  margin: 0 0 1em;
}
.content > p:last-child {
  margin: 0;
}
.item {
  margin: 8px 0;
  display: flex;
  flex-direction: column;
}
.options {
  display: flex;
  flex-wrap: wrap;
}
.label {
  color: #808080;
  flex-grow: 1;
  font-size: 14px;
  break-word: break-word;
}
.value {
  font-size: 12px;
  break-word: break-word;
}
.footer {
  margin-top: 30px;
  text-align: center;
}