.progress {
  top: 123px;
  left: 0;
  right: 0;
  z-index: 9;
  position: fixed;
}
.progress > div {
  height: 5px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1140px;
  border-radius: 0 0 4px 4px;
}
.progressSm {
  top: initial;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  position: absolute;
}
.progressSm > div {
  height: 5px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1140px;
  border-radius: 0;
}