.container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #4c5356;
}
.containerMd {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #4c5356;
}
.containerMd .header {
  padding: 0 23px;
  box-sizing: border-box;
}
.containerMd .search {
  padding: 16px 23px;
}
.containerSm {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #4c5356;
}
.containerSm .header {
  border-bottom: 1px solid #5E686D;
}
.containerSm .cart {
  width: 61px;
}
.containerSm .logo {
  width: 94px;
}
.containerSm .search {
  padding: 16px;
}
.search {
  width: 100%;
  padding: 16px 0;
  max-width: 1140px;
  box-sizing: border-box;
}
.header {
  width: 100%;
  display: flex;
  max-width: 1140px;
  align-items: center;
  justify-content: space-between;
}
.hamburgerMenu {
  padding: 22px;
  box-sizing: border-box;
}
.logo {
  width: 132px;
}
.logo img {
  width: 100%;
  display: block;
}
.login {
  margin-left: auto;
}
.cart {
  width: 117px;
}
.tabs {
  width: 100%;
}
.menuLinks {
  display: flex;
  padding-left: 50px;
}
.menuLinks > :not(:last-child) {
  margin-right: 24px;
}
.menuItem {
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
}