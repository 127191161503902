.container {
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
}
.header {
  display: flex;
  padding: 16px;
  align-items: center;
  flex-direction: column;
}
.title {
  color: #212121;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.caption {
  color: #5F686D;
  font-size: 12px;
}
.body > div:first-child {
  border-top: 1px solid #e5e5e5;
}
.footerBtn {
  color: #FFF;
  height: auto !important;
  padding: 8px 0 8px 16px;
  display: flex;
  font-size: 12px;
  background: #FF6D00;
}
.footerBtn:hover {
  background: #E97020;
}
.footerBtn:active {
  background: #E97020;
}
.disabledFooter {
  padding: 16px;
  background: #727c81;
}
.totalPrice {
  font-size: 14px;
  text-align: left;
}
.makeOrder {
  flex-basis: 110px;
  margin-left: auto;
}
.detailsGroup {
  padding: 16px 10px;
  display: flex;
  border-bottom: 1px solid #e5e5e5;
  flex-direction: column;
}
.detailsGroup:hover {
  background: #f7f7f7;
}
.groupTitle {
  color: #212121;
  font-size: 12px;
}
.groupItems {
  display: flex;
  padding-top: 4px;
  flex-direction: column;
}
.groupItems > div:last-child {
  padding-bottom: 0;
}
.detailItem {
  color: #5F686D;
  display: flex;
  font-size: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
}
.detailItemText {
  flex-basis: 140px;
  margin-right: 10px;
}
.detailItemPrice {
  margin-left: auto;
}
.closeBtn {
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-basis: 26px;
  padding-top: 3px;
  flex-shrink: 0;
  justify-content: flex-end;
}
.closeBtn:hover path {
  fill: #595959;
}