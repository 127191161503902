.container {
  display: flex;
  padding: 24px;
  flex-flow: column nowrap;
  box-sizing: border-box;
  align-items: stretch;
}
.t1139Container {
  display: flex;
  padding: 24px;
  flex-flow: column nowrap;
  box-sizing: border-box;
  align-items: stretch;
  padding-left: 24px;
  padding-right: 24px;
}
.title {
  display: flex;
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  justify-content: center;
}
.t1139Title {
  display: flex;
  font-size: 24px;
  text-align: center;
  line-height: 1;
  font-weight: 500;
  justify-content: center;
}
.content {
  display: flex;
  padding: 16px 0 24px 0;
  box-sizing: border-box;
  justify-content: center;
}
.footer {
  display: flex;
  justify-content: center;
}