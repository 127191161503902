.container {
  width: 100%;
  display: flex;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.15;
}
.containerLg {
  width: 100%;
  display: flex;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.15;
}
.containerLg > div:not(:last-child) {
  border-right: 1px solid #c3c3c3;
}
.containerMd {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.15;
}
.containerMd .srcLocation {
  flex-grow: 0;
  flex-basis: 50%;
  border-right: 1px solid #c3c3c3;
  border-bottom: 1px solid #c3c3c3;
}
.containerMd .dstLocation {
  flex-grow: 0;
  flex-basis: 50%;
  border-bottom: 1px solid #c3c3c3;
}
.containerMd .datespicker {
  flex-grow: 0;
  flex-basis: 50%;
  border-right: 1px solid #c3c3c3;
}
.containerMd .searchBtn {
  height: 56px;
  flex-basis: 100%;
}
.containerSm {
  width: 100%;
  display: flex;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  line-height: 1.15;
  flex-direction: column;
}
.containerSm > div {
  border-bottom: 1px solid #c3c3c3;
}
.containerSm .srcLocation {
  flex-basis: 0;
}
.containerSm .dstLocation {
  flex-basis: 0;
}
.containerSm .datespicker {
  flex-basis: 0;
}
.containerSm .consistSelector {
  flex-basis: 0;
}
.containerSm .searchBtn {
  flex-basis: 56px;
}
.containerSm .swapBtn {
  top: initial;
  right: 22px;
  bottom: -16px;
  transform: rotate(90deg);
}
.swapBtn {
  top: 12px;
  right: -12px;
  z-index: 1;
  position: absolute;
}
.swapBtn img {
  width: 24px;
  height: 32px;
}
.locationCaption {
  top: 22px;
  color: #878787;
  right: 20px;
  position: absolute;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.5px;
}
.location {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 228px;
}
.srcLocation {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 228px;
}
.dstLocation {
  position: relative;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 228px;
}
.datespicker {
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 342px;
  background-color: #fff;
}
.consistSelector {
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 171px;
}
.searchBtn {
  color: #fff;
  display: inline;
  position: relative;
  font-size: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  text-align: center;
  background: #ff6d00;
  flex-basis: 160px;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  justify-content: center;
}
.searchBtn img {
  width: 16px;
  margin-right: 12px;
}
.searchBtn:disabled {
  cursor: default;
  background: #c7c7c7;
}
@keyframes blink {
  0% {
    background-color: #fff;
  }
  15% {
    background-color: #FFD7B9;
  }
  100% {
    background-color: #fff;
  }
}
.blinkingDates {
  animation: blink 600ms ease-in-out 2;
}
.tooltipText {
  margin: 0;
  white-space: pre;
}