.arrow {
  width: 0;
  border: solid transparent;
  height: 0;
  content: " ";
  position: absolute;
  border-color: rgba(51, 51, 51, 0);
  border-width: 8px;
  pointer-events: none;
}
.tooltip {
  color: #fff;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.12));
  z-index: 100001;
  font-size: 12px;
}
.tooltip > div {
  padding: 12px;
  max-width: 40vw;
  border-radius: 3px;
  background-color: #333;
}
.tooltip:global(.fade-exit) {
  opacity: 0.98;
}
.tooltip:global(.fade-exit.fade-exit-active) {
  opacity: 0.001;
  transition: opacity ease 180ms;
}
.tooltip:global(.fade-enter) {
  opacity: 0.001;
}
.tooltip:global(.fade-enter.fade-enter-active) {
  opacity: 0.98;
  transition: opacity ease 250ms;
}
.top {
  color: #fff;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.12));
  z-index: 100001;
  font-size: 12px;
  padding-bottom: 10px;
}
.top > div {
  padding: 12px;
  max-width: 40vw;
  border-radius: 3px;
  background-color: #333;
}
.top:global(.fade-exit) {
  opacity: 0.98;
}
.top:global(.fade-exit.fade-exit-active) {
  opacity: 0.001;
  transition: opacity ease 180ms;
}
.top:global(.fade-enter) {
  opacity: 0.001;
}
.top:global(.fade-enter.fade-enter-active) {
  opacity: 0.98;
  transition: opacity ease 250ms;
}
.top .arrow {
  bottom: -5px;
  border-top-color: #333;
}
.bottom {
  color: #fff;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.12));
  z-index: 100001;
  font-size: 12px;
  padding-top: 10px;
}
.bottom > div {
  padding: 12px;
  max-width: 40vw;
  border-radius: 3px;
  background-color: #333;
}
.bottom:global(.fade-exit) {
  opacity: 0.98;
}
.bottom:global(.fade-exit.fade-exit-active) {
  opacity: 0.001;
  transition: opacity ease 180ms;
}
.bottom:global(.fade-enter) {
  opacity: 0.001;
}
.bottom:global(.fade-enter.fade-enter-active) {
  opacity: 0.98;
  transition: opacity ease 250ms;
}
.bottom .arrow {
  top: -5px;
  border-bottom-color: #333;
}
.right {
  color: #fff;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.12));
  z-index: 100001;
  font-size: 12px;
  padding-left: 10px;
}
.right > div {
  padding: 12px;
  max-width: 40vw;
  border-radius: 3px;
  background-color: #333;
}
.right:global(.fade-exit) {
  opacity: 0.98;
}
.right:global(.fade-exit.fade-exit-active) {
  opacity: 0.001;
  transition: opacity ease 180ms;
}
.right:global(.fade-enter) {
  opacity: 0.001;
}
.right:global(.fade-enter.fade-enter-active) {
  opacity: 0.98;
  transition: opacity ease 250ms;
}
.right .arrow {
  left: -5px;
  border-right-color: #333;
}
.left {
  color: #fff;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.24)) drop-shadow(0 0 2px rgba(0, 0, 0, 0.12));
  z-index: 100001;
  font-size: 12px;
  padding-right: 10px;
}
.left > div {
  padding: 12px;
  max-width: 40vw;
  border-radius: 3px;
  background-color: #333;
}
.left:global(.fade-exit) {
  opacity: 0.98;
}
.left:global(.fade-exit.fade-exit-active) {
  opacity: 0.001;
  transition: opacity ease 180ms;
}
.left:global(.fade-enter) {
  opacity: 0.001;
}
.left:global(.fade-enter.fade-enter-active) {
  opacity: 0.98;
  transition: opacity ease 250ms;
}
.left .arrow {
  right: -5px;
  border-left-color: #333;
}