.overlaidContainer .startDateInput {
  font-size: 15px;
}
.overlaidContainer .endDateInput {
  font-size: 15px;
}
.inputs {
  width: 100%;
  height: 56px;
  display: flex;
}
.input {
  color: #444;
  height: 56px;
  padding: 12px 16px;
  position: relative;
  min-width: 0;
  font-size: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 0;
  flex-shrink: 0;
}
.input img {
  top: 19px;
  width: 18px;
  right: 15px;
  position: absolute;
}
.invalidInput {
  color: #d50000;
  border-width: 2px;
  border-style: solid;
  border-color: #d50000;
}
.startDateInput {
  color: #444;
  height: 56px;
  padding: 12px 16px;
  position: relative;
  min-width: 0;
  flex-grow: 1;
  font-size: 16px;
  box-sizing: border-box;
  flex-basis: 0;
  flex-shrink: 0;
  border-right: 1px solid #c3c3c3;
}
.startDateInput img {
  top: 19px;
  width: 18px;
  right: 15px;
  position: absolute;
}
.invalidStartDateInput {
  color: #d50000;
  border-width: 2px;
  border-style: solid;
  border-color: #d50000;
}
.endDateInput {
  color: #444;
  height: 56px;
  padding: 12px 16px;
  position: relative;
  min-width: 0;
  font-size: 16px;
  flex-grow: 1;
  box-sizing: border-box;
  flex-basis: 0;
  flex-shrink: 0;
}
.endDateInput img {
  top: 19px;
  width: 18px;
  right: 15px;
  position: absolute;
}
.invalidEndDateInput {
  color: #d50000;
  border-width: 2px;
  border-style: solid;
  border-color: #d50000;
}
.clearBtn {
  color: #ff6d00;
  height: 48px;
  margin: 24px;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  line-height: 48px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-color: #ff6d00;
  border-radius: 4px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
}
.header {
  color: #fff;
  height: 65px;
  padding: 15px 20px;
  display: flex;
  position: relative;
  font-size: 14px;
  box-sizing: border-box;
  background: #5F686D;
  align-items: center;
  justify-content: space-between;
}
.header img {
  width: 12px;
  cursor: pointer;
}