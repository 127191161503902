.container {
  width: 200px;
  cursor: pointer;
  display: flex;
  padding: 6px 16px;
  box-sizing: border-box;
  background: #FF6D00;
  align-items: center;
  border-radius: 19px;
  justify-content: center;
}
.text {
  color: #FFF;
  font-size: 14px;
  font-family: Helvetica Neue;
  font-weight: 500;
  line-height: 12px;
  padding-left: 10px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}