.container {
  width: 100%;
  height: 100%;
  position: relative;
}
.loader {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.circular {
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  height: 100%;
  bottom: 0;
  margin: auto;
  position: absolute;
  animation: rotate 2000ms linear infinite;
  transform-origin: center center;
}
.path {
  stroke: #ff7e00;
  animation: dash 1500ms ease-in-out infinite;
  stroke-linecap: round;
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124;
  }
}