.container {
  display: flex;
  position: relative;
  flex-direction: column;
}
.containerSm {
  position: static;
}
.containerSm .menuWrapper {
  left: 0;
  right: 0;
  width: 100%;
}
.containerSm .menu {
  border-radius: 0;
}
.containerSm .menuWrapper:before {
  right: 78px;
}
.subContainer {
  display: flex;
}
.menu {
  overflow: hidden;
  border-radius: 4px;
}
.menuWrapper {
  top: 56px;
  right: 0;
  width: 280px;
  z-index: 10;
  position: absolute;
}
.menuWrapper:before {
  top: -6px;
  right: 13px;
  width: 0;
  height: 0;
  content: "";
  display: block;
  position: absolute;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 6px solid #fff;
}
.btnWrapperSm {
  border-left: 1px solid #5E686D;
}
.telegramBtnWrapper {
  display: flex;
}