.container {
  width: 100%;
  padding: 14px 16px;
  display: flex;
  align-items: center;
}
.container:hover {
  background: #3f4446;
}
.textContainer {
  color: #fff;
  font-size: 12px;
  padding-left: 9px;
}
.active {
  background: #3f4446;
}